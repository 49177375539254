<template>
  <div class="date-input" :class="classes">
    <template v-if="edit">
      <input
        :id="inputId"
        ref="input"
        type="date"
        :value="value"
        class="button"
        @input="updateValue($event.target.value)"
        @change="updateValue($event.target.value)"
        @focus="focus"
      />
      <label :for="inputId">
        <slot></slot>
      </label>
      <span v-if="validationError" class="validation-error">{{ validationError }}</span>
    </template>
    <div v-else class="read-only-value">
      <span>{{ value }}</span>
      <slot></slot>
    </div>
  </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid'

export default {
  props: {
    value: {
      type: String,
    },
    invalidValues: {
      type: Array,
    },
    invalidValuesErrorMessage: {
      type: String,
    },
    edit: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      inputId: uuidv4(),
    }
  },

  computed: {
    validationError() {
      if (this.invalidValues && this.invalidValues.includes(this.value)) {
        return this.invalidValuesErrorMessage
      }
      return null
    },

    classes() {
      return {
        invalid: Boolean(this.validationError),
      }
    },
  },

  methods: {
    getValidationError(value) {
      if (this.invalidValues && this.invalidValues.includes(value)) {
        return this.invalidValuesErrorMessage
      }
      return null
    },

    updateValue(value) {
      this.$emit('input', value)
      this.$emit('validation', !this.getValidationError(value))
    },

    focus() {
      this.$refs.input.select()
    },
  },
}
</script>

<style>
.date .read-only-value {
  /* padding: var(--box-padding); */
  border: 1px solid transparent;
}

.date > input {
  border-color: var(--hairline-color);
  width: 100%;
  overflow: visible;
  text-overflow: initial;
}

.date > input:focus {
  border-color: var(--secondary-highlight-color);
}

.date > input,
.date.invalid > .validation-error {
  color: var(--error-color);
}

.date.invalid > input {
  border: var(--box-error-border);
}

.date.invalid > .validation-error {
  display: block;
  font-weight: 400;
  font-size: var(--font-xs);
}
</style>
