<i18n>
{
  "de": {
    "pageTitle": "Verwaltung",
    "portfolioActivationTitle": "Aktivierung",
    "activeUntilLabel": "Aktiv bis",
    "cancelButtonLabel": "Abbrechen",
    "portfolioGeneralTitle": "Allgemein",
    "portfolioUsersTitle": "Zugangsrechte",
    "portfolioUsersButton": "Benutzer/-innen verwalten",
    "updateNamePendingMessage": "Portfolioname wird gespeichert...",
    "editButtonLabel": "Portfolioname ändern",
    "saveButtonLabel": "Speichern",
    "manageBuildings": "Liegenschaften verwalten",
    "portfolioSettings": "Einstellungen",
    "energyCarrierSettings": "Energieträger",
    "heatingTypesSettings": "Heizungstypen",
    "investmentCostsSettings": "Kostenkennzahlen",
    "lifespansSettings": "Lebensdauer",
    "exportTitle": "Export"
  }
}
</i18n>

<template>
  <MainLayout :ready="portfolioReady && portfolioUsersReady" :portfolio="portfolio" class="admin-portfolio-page">
    <template #default>
      <p v-if="updateNameError">{{ updateNameError }}</p>
      <p v-else-if="updateNamePending">{{ $t('updateNamePendingMessage') }}</p>
      <template v-else>
        <template v-if="editName">
          <form class="portfolio-name" @keydown.esc="onCancel">
            <TextInput v-model="name" :min-length="1" :edit="true" />
            <ButtonWrapper>
              <button class="button" type="submit" :disabled="submitDisabled" @click.prevent="onNameSave">
                {{ $t('saveButtonLabel') }}
              </button>
              <button class="button" @click.prevent="onCancel">{{ $t('cancelButtonLabel') }}</button>
            </ButtonWrapper>
          </form>
        </template>
        <template v-else>
          <PageTitle :title="$t('pageTitle')" :portfolio="portfolio" />

          <h2>{{ $t('portfolioActivationTitle') }}</h2>
          <FormRow :label="$t('activeUntilLabel')">
            <DateInput v-model="activeUntil" :edit="true" />
          </FormRow>
          <ButtonWrapper>
            <button class="button" @click.prevent="onActiveUntil">{{ $t('saveButtonLabel') }}</button>
          </ButtonWrapper>

          <h2>{{ $t('portfolioGeneralTitle') }}</h2>
          <ButtonWrapper>
            <button class="button" @click.prevent="onEdit">{{ $t('editButtonLabel') }}</button>
            <router-link
              class="button"
              :to="{ name: 'adminPortfolioBuildings', params: { portfolio_id: portfolio.id } }"
              >{{ $t('manageBuildings') }}</router-link
            >
            <router-link
              class="button"
              :to="{ name: 'adminPortfolioSettings', params: { portfolio_id: portfolio.id } }"
              >{{ $t('portfolioSettings') }}</router-link
            >
          </ButtonWrapper>

          <h2>{{ $t('portfolioUsersTitle') }}</h2>
          <ul v-if="hasUsers" class="users-list" :class="usersListClasses">
            <PortfolioUserRow v-for="user in users" :key="user.id" class="user-list-row" :user="user" />
          </ul>
          <ButtonWrapper>
            <router-link class="button" :to="{ name: 'adminPortfolioUsers', params: { portfolio_id: portfolio.id } }">{{
              $t('portfolioUsersButton')
            }}</router-link>
          </ButtonWrapper>

          <h2>{{ $t('exportTitle') }}</h2>
          <ButtonWrapper>
            <ExportPortfolio :portfolio="portfolio" />
          </ButtonWrapper>
        </template>
      </template>
    </template>
  </MainLayout>
</template>

<script>
import { mapActions } from 'vuex'

import MainLayout from '@/pages/layouts/MainLayout.vue'
import AllPortfoliosMixin from '@/pages/vuex-mixins/AllPortfoliosMixin.vue'
import PortfolioMixin from '@/pages/vuex-mixins/PortfolioMixin.vue'
import PortfolioUsersMixin from '@/pages/vuex-mixins/PortfolioUsersMixin.vue'

import ButtonWrapper from '@/components/shared/ButtonWrapper.vue'
import FormRow from '@/components/shared/forms/FormRow.vue'
import TextInput from '@/components/shared/forms/TextInput.vue'
import DateInput from '@/components/shared/forms/DateInput.vue'
import ExportPortfolio from '@/components/admin-portfolios/ExportPortfolio.vue'
import PortfolioUserRow from '@/components/admin-portfolios/PortfolioUserRow.vue'
import PageTitle from '@/components/shared/PageTitle.vue'

export default {
  name: 'adminPortfolioDetails',

  mixins: [
    AllPortfoliosMixin, // Provides: allPortfolios, allPortfoliosReady
    PortfolioMixin, // Provides: portfolio, portfolioLoading, portfolioReady
    PortfolioUsersMixin, // Provides: portfolio.users, portfolioUsersLoading, portfolioUsersReady
  ],

  components: {
    MainLayout,
    ButtonWrapper,
    FormRow,
    TextInput,
    DateInput,
    ExportPortfolio,
    PortfolioUserRow,
    PageTitle,
  },

  data() {
    return {
      name: null,
      activeUntil: null,
      editName: false,
      updateNameError: null,
      updateNamePending: false,
    }
  },

  computed: {
    users() {
      return this.portfolio && this.portfolio.users
    },

    hasUsers() {
      return Boolean(this.users && this.users.length)
    },

    usersListClasses() {
      return {
        'with-admin-column': this.hasAdmins,
      }
    },

    submitDisabled() {
      return this.name === null || this.name.length < 1
    },
  },

  watch: {
    portfolio(val, oldVal) {
      const { name } = val || {}
      this.name = name
      this.activeUntil = this.portfolio.inactive_after
    },
  },

  mounted() {
    this.activeUntil = this.portfolio && this.portfolio.inactive_after
  },

  methods: {
    ...mapActions({
      loadUsersById: 'portfolio/loadUsersById',
      updatePortfolioName: 'portfolio/updatePortfolioName',
      updatePortfolioInactiveAfter: 'portfolio/updatePortfolioInactiveAfter',
    }),

    onCancel() {
      this.editName = false
      this.name = ''
    },

    isActive(portfolio) {
      if (portfolio.inactive_after === null) {
        return true
      } else {
        const inactive = new Date(Date.parse(portfolio.inactive_after))
        inactive.setHours(23, 59, 59, 999)
        return new Date() <= inactive
      }
    },

    async onActiveUntil() {
      await this.updatePortfolioInactiveAfter({
        id: this.portfolio.id,
        inactiveAfter: this.activeUntil === '' ? null : this.activeUntil,
      })
    },

    async onNameSave() {
      try {
        this.updateNamePending = true
        this.updateNameError = null
        await this.updatePortfolioName({ id: this.portfolio.id, name: this.name })
      } catch (error) {
        this.updateNameError = error
      }
      this.updateNamePending = false
      this.editName = false
      this.name = ''
    },

    onEdit() {
      this.name = this.portfolio && this.portfolio.name
      this.editName = true
    },
  },

  head() {
    return {
      title: () => {
        return { inner: this.$t('pageTitle') }
      },
    }
  },
}
</script>

<style lang="scss">
.admin-portfolio-page {
  & .portfolio-name {
    display: grid;
    grid-template-columns: repeat(2, auto);
    align-items: baseline;
    justify-content: start;
    gap: var(--spacing-l);
    row-gap: var(--spacing-s);
    margin-bottom: var(--spacing-m);
  }

  & .portfolio-name h1 {
    margin-bottom: 0;
  }

  & .users-list {
    display: grid;
    grid-template-columns: repeat(5, auto);
    gap: var(--spacing-l);
    row-gap: var(--spacing-s);
    justify-content: start;
    justify-items: start;
    align-items: baseline;
  }

  & .users-list.with-admin-column {
    grid-template-columns: repeat(5, auto);
  }

  & .users-list .portfolio-user-row {
    display: contents;
  }
}
</style>
